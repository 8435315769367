import React, { Component } from "react";
//import { data } from "autoprefixer";
//import e from "cors";
import Counter, { Counter2 } from "./Counter";
import Favorite, { Favorite2 } from "./Favorite";
import { ReactComponent as TickIcon } from '../assets/pic/tick.svg';
import { ReactComponent as NewIcon } from '../assets/pic/new.svg';
import discountImage from '../assets/pic/discount.png';
import newImage from '../assets/pic/new.png';
import tickImage from '../assets/pic/tick.png';


import BlankBasket from "./BlankBasket";
import DetailedBasket from "./DetailedBasket";
import { ReactComponent as DiscountIcon } from '../assets/pic/discount.svg';
import Loading from "../components/Loading";
import { Text } from '../languages/Language';
import { CURRENCY as NEWCURRENCY, DIGITS, IMAGEURL } from '../constants';
import { ReactComponent as CartIcon } from '../assets/pic/cart.svg';
import {roundDigits} from "../utils";
import SlipDeleteAllLines from "../slip/SlipDeleteAllLines";
import SlipSave from "../slip/SlipSave";
import { IMAGETIMESTAMP } from "../App";

class Korb extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {


    const discRate = sessionStorage.getItem('discRate');
    const itemsPrice = this.props.cartItems?.reduce((a, c) =>
      a + c.itemMainUnitVpe
      * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(DIGITS) : c.itemPrice?.toFixed(DIGITS))
      * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate>0 ? discRate / 100 : 0)))
      * c.itemAmount, 0);

    const itemsPriceTax = this.props.cartItems?.reduce((a, c) =>
      a + c.itemMainUnitVpe
      * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(DIGITS) : c.itemPrice?.toFixed(DIGITS))
      * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate > 0 ? discRate / 100 : 0)))
      * (1 + c.itemVat / 100)
      * c.itemAmount, 0);

    const handleDelete = () => {
      sessionStorage.removeItem('Expl1');
      sessionStorage.removeItem('Expl2');

  };

    return (
      <>
        <div
         className={`${this.props.isCartOpen ?
          "w-[50rem] xl:w-[42rem] flex flex-col pointer-events-auto h-full block md:hidden":
          "w-[50rem] xl:w-[42rem] flex flex-col pointer-events-auto pl-6 h-full hidden md:flex " }`}
        >
          <div className="flex flex-row justify-between items-center">
          <button
              className="p-1 bg-transparent border-0 text-black mb-1
                         opacity-50 float-right text-xl leading-none font-semibold
                         outline-none focus:outline-none"
              onClick={() => this.props.toggleCart()}
                                    >
              <span
                className="bg-transparent text-black h-6 w-6 text-xl
                         block outline-none focus:outline-none">
                                            &#10006;
                </span>
            </button>
            <h2 className="text-base mr-auto font-medium text-company-primary-color my-2">
                <Text tid="ShoppingCart"/>
            </h2>

                    <div
                      className="text-base md:text-xs lg:text-base md:mt-1 lg:mt-0.5 font-medium text-company-primary-color mt-0.5 "
                        >

                          {
                            roundDigits(itemsPrice?.toFixed(DIGITS))

                          }	&nbsp;
                          {NEWCURRENCY} &nbsp; / &nbsp; {
                            roundDigits(itemsPriceTax?.toFixed(DIGITS))

                          }	&nbsp;
                          {NEWCURRENCY}

                    </div>


          </div>

          <div
            className="flex  w-full flex-col border-t-8 border-b-8 border-r-2 border-company-primary-color border-company-primary-color overflow-auto"
          >
            {
              this.props.cartItems?.length !== 0
                && sessionStorage.getItem('token')
                && itemsPrice
                ? (
                  <div className="flex flex-col h-full " >

                    <div className="flex flex-row sticky top-0 z-20 bg-white border-b border-slate-200 p-2 w-full justify-between">
                      <div className="flex flex-row space-x-1">
                        <SlipDeleteAllLines
                          onSlipDeleteAllLines={() => {
                            this.props.onSlipDeleteAllLines();
                            this.props.ShowDetailedBasket(false);
                            handleDelete();
                          }}
                        />

                        <SlipSave
                          onSlipDeleteAllLines={() => {
                            this.props.onSlipDeleteAllLines();
                            this.props.ShowDetailedBasket(false);
                            handleDelete();
                          }}
                        /></div>
                        <DetailedBasket
                        ShowDetailedBasket={(value) => this.props.ShowDetailedBasket(value)}
                        />

                    </div>

                    <div
                      className=""
                    >
                      <div
                        className="flow-root p-1"
                      >
                        <ul
                          className="flex flex-col divide-y divide-gray-200 h-full overflow-hidden"
                        >
                          {
                            this.props.cartItems
                            && this.props.cartItems?.map((record, index) => {
                              return (
                                <li
                                  className="flex flex-row justify-between items-center p-1"
                                  key={index}
                                >
                                  <div className="relative px-1">

                                        <div className="absolute bottom-[30px] left-[0px] z-[1] w-[14px] h-[14px]">

                                        </div>

                                  <div className="absolute bottom-[10px] left-[0px] z-[1]">
                                        <Favorite2

                                          className="shadow-md shadow-slate-400"
                                          basket={true}
                                          itemRef={record.itemRef}
                                          itemFavorite={record.itemFavorite}
                                          item={record}
                                          onAddAllFavorites={this.props.onAddAllFavorites}
                                          onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                          />
                                        </div>

                                      <div className="flex justify-center items-center rounded-sm border bg-white
                                       border-slate-200  absolute bottom-[-3px] left-[0px] z-[1]">
                                        <div
                                          className={
                                            (record.itemOnHand === 0
                                              ? "h-2 w-2 bg-red-500 rounded-full m-0.5"
                                              : "h-2 w-2 bg-green-500 rounded-full m-0.5")
                                          }
                                        >
                                        </div>
                                        </div>

                                        {record.itemCampaign === 1
                                            ? <div
                                            className=" font-bold flex flex-row items-center
                                        text-red-600 pt-0.5 absolute bottom-[-3px] left-[45px] z-[1]
                                        w-[14px] h-[14px]
                                         rounded-lg">

                                          <img src={discountImage}></img>
                                        </div>
                                        :null
                                        }

                                        {
                                           record.itemSold === 1
                                           ? <div className={`
                                           ${record.itemAmount
                                                   ? "border-company-secondary-color"
                                                   : "border-slate-300"
                                               }
                                           absolute bottom-[-8px] left-[12px] flex flex-row items-center p-1 justify-between
                                           text-company-primary-color w-[22px] h-[22px] rounded-lg z-10`
                                           }
                                           >
                                               <img src={tickImage}></img>
                                           </div>
                                           : null
                                        }

                                {record.itemNew === 1
                                ? <div className="shadow-md shadow-slate-400">
                                    {sessionStorage.getItem('token')
                                        ?
                                        <div className="absolute left-[28px] bottom-[-6px] w-[15px] h-[15px]
                                     m-0.5 shadow-slate-500 z-10
                                     rounded-lg shadow-2xl">
                                          <img src={newImage}></img>
                                        </div>

                                        : null
                                    }
                                </div>
                                : null
                            }

                                  <div
                                    className=" relative w-16 h-12 overflow-hidden rounded-md border border-slate-200 flex item-center"
                                  >

                                    <img
                                      alt=""
                                      src={IMAGEURL + "/thumbs/TH" + record.itemCode + ".jpg?"+ IMAGETIMESTAMP}
                                      onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src = "noImage.jpg";
                                      }
                                      }
                                      className="p-1 w-full h-full object-contain "
                                    />

                                  </div>
                                  </div>
                                  <div
                                    className="flex flex-col text-very-small text-slate-500 justify-start w-3/4 m-1"
                                  >
                                    <div className="flex flex:row justify-between">
                                    <p className="">
                                      {record.itemName1}
                                    </p>

                                    </div>

                                    <div
                                      className="flex flex-row justify-start"
                                    >

                                      <p
                                        className=""
                                      >
                                        {record.itemCode} / {record.itemUnitCode + "(" + record.itemMainUnitVpe + ")"}
                                      </p>

                                    </div>
                                    <div className="text-[12px]">
                                    <span className={`
                                        ${record.itemCampaignPrice >0  ? "line-through" : ""}
                                        font-bold text-company-primary-color
                                    `}>
                                        {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY} {" "}
                                    </span>
                                    {record.itemCampaignPrice >0  && (
                                        <span className="font-bold text-company-primary-color ml-2">
                                            {roundDigits(record.itemCampaignPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                        </span>
                                    )}
                                    <span className="font-bold">
                                        {" x "}{record.itemMainUnitVpe}{" x "}{record.itemAmount}{" = "}
                                        {roundDigits(((record.itemCampaignPrice ? record.itemCampaignPrice : record.itemPrice) * record.itemMainUnitVpe * record.itemAmount).toFixed(DIGITS))}
                                        {" "}{NEWCURRENCY}
                                    </span>
                                </div>

                                      {
                                        /*
                                        record.itemCampaignPrice > 0
                                          ? <div className="flex flex-row justify-between w-full font-bold text-xxs text-red-600">
                                            <div className={`${record.itemCampaignPrice
                                              ? "line-through"
                                              : ""
                                              } text-company-primary-color`}>
                                              {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                            </div>
                                            <div>
                                              {roundDigits(record.itemCampaignPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                            </div>
                                          </div>
                                          : sessionStorage.getItem("discRate")
                                            ? <div className="flex flex-row justify-between w-full font-bold text-xxs text-company-primary-color">
                                              <div className="flex flex-row">
                                                {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                              </div>
                                              <div className="text-red-600">{sessionStorage.getItem("discRate") > 0 ? JSON.parse(sessionStorage.getItem("discRate"))+ '%' : null}</div>
                                              <div className="text-red-600 justify-between flex flex-row">
                                              </div>
                                            </div>
                                            : <div className="flex flex-row justify-between w-full font-bold text-xxs text-company-primary-color">
                                              <div className="flex flex-row">
                                                {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                                              </div>
                                            </div>
                                            */
                                      }
                                  </div>
                                  <div
                                    className="flex"
                                  >

                                  </div>
                                  <div
                                    className=""
                                  >
                                    <Counter2
                                      basket={true}
                                      count={record.itemAmount}
                                      onClickIncrement={() => this.props.onAdd(record)}
                                      onClickDecrement={() => this.props.onRemove(record)}
                                    />
                                  </div>
                                </li>
                              );
                            }
                            )
                          }
                        </ul>
                      </div>
                    </div>


                  </div>
                )
                :
                (!sessionStorage.getItem('slipRef')
                  && sessionStorage.getItem('token')
                  ? <div
                    className="flex w-full items-center justify-center"
                  >
                    <Loading />
                  </div>
                  :
                  <div
                    className="h-96"
                  >
                    <BlankBasket />
                  </div>)
            }
          </div>
        </div>
      </>
    );
  }
}

export default Korb;