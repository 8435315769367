import React from 'react'
import Counter from "./Counter";
import Favorite from "./Favorite";
import { ReactComponent as NewIcon } from '../assets/pic/new.svg';
import { ReactComponent as DiscountIcon } from '../assets/pic/discount.svg';
import { ReactComponent as TickIcon } from '../assets/pic/tick.svg';
import discountImage from '../assets/pic/discount.png';
import newImage from '../assets/pic/new.png';
import tickImage from '../assets/pic/tick.png';
import {roundDigits} from '../utils';
import ItemModal from './ItemModal';
import { CURRENCY as NEWCURRENCY, DIGITS, IMAGEURL, FLAG_ITEM_ON_HAND } from '../constants';
import { IMAGETIMESTAMP } from '../App';

export default function Item(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [url] = React.useState("noImage.jpg");
    const [imgurl] = React.useState(IMAGEURL+ "/thumbs/TH");

    if (!props.item)
        return null;

    return (
        <>
            {showModal ?
                (<ItemModal
                    value={props.value}
                    handleChangeCount={props.handleChangeCount}
                    count={props.item.itemAmount}
                    onAdd={props.onAdd}
                    onRemove={props.onRemove}
                    item={props.item}
                    onSetModal={setShowModal}
                    onAddAllFavorites={props.onAddAllFavorites}
                    onRemoveAllFavorites={props.onRemoveAllFavorites}
                    itemRef={props.item.itemRef}
                    itemFavorite={props.item.itemFavorite}
                />
                )
                : null
            }
            <div
                className="relative w-48 p-4 hover:bg-gray-100 "
            >
                <div
                    className={`
                ${props.item.itemAmount
                            ? "border-company-secondary-color"
                            : "border-slate-300"
                        }
                bg-white  flex flex-wrap flex-col border-2 rounded-xl
                `}
                >
                    <div
                        className="flex-col flex w-40 p-1 justify-center"
                    >
                        <div
                            className="flex h-40 rounded bg-white"
                        >
                            <img
                                alt=""
                                onClick={() => setShowModal(true)}
                                src={imgurl
                                    + props.item.itemCode
                                    + ".jpg?"+ IMAGETIMESTAMP}
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src = url;
                                }}
                                title={props.item.itemName1}
                                className="p-1 w-full h-full object-contain"
                            />

                            <div
                                className="flex h-7 w-7 items-center justify-center  border-2
                                rounded-lg bg-white absolute top-[167px] left-[7px] "
                            >
                                <div
                                    className={
                                        props.item.itemOnHand === 0
                                            ? "h-3 w-3 bg-red-500 rounded-full"
                                            : "h-3 w-3 bg-green-500 rounded-full"
                                    }
                                ></div>
                            </div>
                            <div
                                className="shadow-md shadow-slate-400 absolute top-[100px] left-[-15px] "
                            >
                                {sessionStorage.getItem('token')
                                    ? <Favorite
                                        className="shadow-md shadow-slate-400"
                                        basket={false}
                                        itemRef={props.item.itemRef}
                                        itemFavorite={props.item.itemFavorite}
                                        item={props.item}
                                        onAddAllFavorites={props.onAddAllFavorites}
                                        onRemoveAllFavorites={props.onRemoveAllFavorites}
                                    />
                                    : null
                                }
                            </div>
                            {props.item.itemNew === 1
                                ? <div className="shadow-md shadow-slate-400">
                                    {sessionStorage.getItem('token')
                                        ?
                                        <div className="absolute left-[63px] top-[168px]  w-6 h-6
                                        m-0.5 shadow-slate-500
                                        rounded-lg shadow-2xl">
                                            <img src={newImage}></img>
                                        </div>

                                        : null
                                    }
                                </div>
                                : null
                            }



                            {
                                sessionStorage.getItem('token') &&
                                    props.item.itemCampaignPrice > 0
                                    ? <div
                                        className=" font-bold flex flex-row items-center
                                                text-red-600 shadow-slate-400 absolute
                                                top-[166px] right-[3.5px] sm:w-26 h-[27px] bg-white m-0.5 border
                                                border-red-600  rounded-lg">
                                        <span
                                            className='ml-1 text-xs'
                                        >
                                            {roundDigits(props.item.itemCampaignPrice?.toFixed(DIGITS))} {NEWCURRENCY}
                                        </span>
                                        <DiscountIcon
                                            className=" ml-1 w-5 h-5"
                                        />
                                    </div>
                                    : props.item.itemCampaign
                                        ? <div
                                            className=" font-bold flex flex-row items-center
                                        text-red-600   absolute
                                        top-[170px] right-[75px] w-6 h-6
                                          rounded-lg">

                                        <img src={discountImage}></img>
                                        </div> : null
                            }



                            {sessionStorage.getItem('token')
                                && props.item.itemSold === 1
                                ? <div className={`
                                ${props.item.itemAmount
                                        ? "border-company-secondary-color"
                                        : "border-slate-300"
                                    }
                                absolute top-[166px] left-[34px] flex flex-row items-center p-1 justify-between
                                text-company-primary-color w-8 h-8 rounded-lg`
                                }
                                >
                                    <img src={tickImage}></img>
                                </div>
                                : null
                            }
                            <div
                                className={`
                            ${!props.item.itemOnHand && FLAG_ITEM_ON_HAND
                                        ? "hidden"
                                        : ""}
                            `}
                            >
                                {sessionStorage.getItem('token')
                                    ? <Counter
                                        basket={false}
                                        count={props.item.itemAmount}
                                        onClickIncrement={() => props.onAdd(props.item)}
                                        onClickDecrement={() => props.onRemove(props.item)}
                                    />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-col flex grow w-full justify-between mt-2 ml-1 ">
                <div className="text-sm h-10 overflow-hidden my-2">
                    {props.item.itemName1 || <span>&nbsp;</span>}
                </div>
                <div className="text-slate-400 text-xs h-5 overflow-hidden my-1 whitespace-nowrap text-ellipsis">
                {props.item.itemName2 || <span>&nbsp;</span>}
                </div>
                <div className="text-slate-400 text-xs my-1">
                    {props.item.itemCode} / {props.item.itemUnitCode
                        + "(" + props.item.itemMainUnitVpe
                        + ")"}
                </div>
                </div>

                <div
                    className="flex px-1 justify-between my-1"
                >
                    {sessionStorage.getItem('token')
                        ? <div className="flex flex-row text-[12px]">
                            <div>
                            {props.item.itemCampaignPrice > 0 ? (
                            <span className="font-bold text-company-primary-color">
                                {roundDigits(props.item.itemCampaignPrice.toFixed(DIGITS))} {NEWCURRENCY}
                            </span>
                        ):null}
                        {props.item.itemCampaignPrice > 0 ? (
                            <br></br>
                        ):null}
                        <span className={`
                            ${props.item.itemCampaignPrice > 0 ? "line-through" : "font-bold"}
                             text-company-primary-color
                        `}>
                            {roundDigits(props.item.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                        </span>
                            </div>
                        <span className="text-slate-400 ml-1">
                         {"x "}{props.item.itemMainUnitVpe}{" x "}{props.item.itemAmount}{" = "}
                            {roundDigits(((props.item.itemCampaignPrice > 0 ? props.item.itemCampaignPrice : props.item.itemPrice) * props.item.itemMainUnitVpe * props.item.itemAmount).toFixed(DIGITS))}
                            {" "}{NEWCURRENCY}
                        </span>
                    </div>


                        : null
                    }

                </div>
            </div>
        </>
    )
}
